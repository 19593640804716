<template>
	<div>
		<!-- 顶部轮播图 -->
		<mzSwiper :image='bannerImg'></mzSwiper>
		<!-- //顶部轮播图 -->
		<!-- 产品区域 -->
		<div class="content pt42 pb40">
			<!-- 导航区域 -->
			<div class="flex mr58 ml38 nav_box">
				<div class="flex1 h44 fs12 fw-500 nav_item" :class="{'active':item.id==query.categoryId}"
					v-for="(item,i) in CategoryList" :key="i" @click="checkTab(item.id)">{{item.categoryName}}</div>
			</div>
			<!-- //导航区域 -->

			<!-- 产品每一项 -->
			<div class="mr16 ml16 mt20" v-for="(item,i) in goodsList" :key="i">
				<img :src="item.wapPic" class="w-max h270">
				<div class="goods_content">
					<div class="fs16 fw-500 color0">{{item.productName}}</div>
					<div class="fs12 color6 mt8">{{item.productIntro}}</div>
					<van-button type="default" class="mt12" @click="$router.push(`/goodsD/${item.id}`)">查看详情>
					</van-button>
				</div>
			</div>
			<!-- //产品每一项 -->
		</div>
		<!-- //产品区域 -->
	</div>
</template>

<script>
	import { getProductCategory, getProducts } from '@/api'
	//引入顶部banner的minix
	import bannerImgJs from '@/mixin/bannerImg.js'
	export default {
		name: 'goodsCenter',
		data() {
			return {
				CategoryList: [],
				query: {
					categoryId: null,
					pageNum: 1,
					pageSize: 100
				},
				goodsList: [],
				bannerType: 2,
			};
		},
		mixins: [bannerImgJs],
		components: {},
		created() {
			this.query.categoryId = this.$route.params.id
			//获取产品分类
			this.getProductCategory()
		},
		watch: {
			'$route': function(to, from) {
				this.query.categoryId = this.$route.params.id
				this.getProductCategory()
			}
		},
		mounted() {},

		methods: {
			//获取产品分类
			async getProductCategory() {
				const res = await getProductCategory()
				this.CategoryList = res.data
				this.query.categoryId ? '' : this.query.categoryId = res.data[0].id
				this.getProducts()
			},
			//获取产品列表
			async getProducts() {
				const res = await getProducts(this.query)
				this.goodsList = res.data.obj
			},
			//tab栏切换
			checkTab(id) {
				this.query.categoryId = id
				this.getProducts()
			}
		}
	}
</script>
<style lang='scss' scoped>
	.content {
		border: .1px solid transparent;

		.nav_box {
			background: transparent;
			margin-top: -68px;

			.nav_item {
				z-index: 2;
				background: #F2F2F2;
				color: #787878;
				line-height: 44px;
				text-align: center;
			}

			.active {
				background: #F7B500;
				color: #000000;
			}
		}

		.goods_content {
			padding: 16px;
			background: #F9F9F9;

			::v-deep .van-button {
				width: 127px;
				height: 28px;
				border: 1px solid #272727;
				font-size: 12px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #272727;
			}
		}
	}
</style>
